<template>
    <div v-if="shipment && shipment.id">
        <v-subheader style="font-size: 12px; color: grey; height: 36px;">Custom Events 
            <v-spacer></v-spacer>
            <v-tooltip top v-if="shipment.documentationStatus == 'OPEN'">
              <template v-slot:activator="{ on }">
                <v-btn small v-on="on" text @click="customEventModal = true">
                  <v-icon small>add_circle_outline</v-icon>
                </v-btn>
              </template>
              Add Custom Event
            </v-tooltip>
        </v-subheader>
        <v-divider></v-divider>
        <v-list dense class="my-0 py-0" style="background: transparent !important;">
          <v-list-item v-if="events.length == 0 && customEvents.length == 0">
            <v-list-item-content class="text-center">
              <span style="color: grey">No available events</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="event in events" :key="event.id" @click="viewEvent(event)">
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="mr-2" v-on="on" :color="getStatus(event.status).color">{{
                    getStatus(event.status).icon }}</v-icon>
                </template>
                <span>{{ event.status }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ event.title }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="event.reference">
                {{ event.reference }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="event in customEvents" :key="event.id" @click="editCustomEvent(event)">
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="mr-2" v-on="on" :color="getStatus(event.status).color">{{
                    getStatus(event.status).icon }}</v-icon>
                </template>
                <span>{{ event.status }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ event.shipmentCustomEventType.description }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="event.reference">
                {{ event.reference }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-subheader style="font-size: 12px; color: grey; height: 36px;">System Actions</v-subheader>
        <v-divider></v-divider>
        <v-list dense class="my-0 py-0" style="background: transparent !important;">
          <v-list-item style="height: 30px">
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" v-if="shipment.fileStatus == 'Pending'"
                    color="orange">schedule</v-icon>
                  <v-icon v-bind="attrs" v-on="on" v-else-if="shipment.fileStatus == 'In Progress'"
                    color="blue">pending</v-icon>
                  <v-icon v-bind="attrs" v-on="on" v-else-if="shipment.fileStatus == 'Complete'"
                    color="green">task_alt</v-icon>
                </template>
                <span style="font-size: 12px">{{
                  shipment.fileStatus
                }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('documentCreationStatus') }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="shipment.documentationStatus == 'OPEN'">
              <v-tooltip top v-if="!shipment.fileLocked && shipment.fileStatus == 'Complete'">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="$emit('refreshShipmentDocuments')" icon><v-icon>refresh</v-icon></v-btn>
                </template>
                <span style="font-size: 12px">Regenerates all documents for file & resets all
                  tasks</span>
              </v-tooltip>
              <v-tooltip top v-else-if="shipment.fileLocked">
                <template v-slot:activator="{ on }"><v-icon v-on="on" color="amber">lock</v-icon>
                </template>
                <span style="font-size: 12px">File sync is locked, changes must be made via the container.</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-list-item style="height: 30px">
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" v-if="shipment.taskStatus == 'Pending'"
                    color="orange">schedule</v-icon>
                  <v-icon v-bind="attrs" v-on="on" v-else-if="shipment.taskStatus == 'In Progress'"
                    color="blue">pending</v-icon>
                  <v-icon v-bind="attrs" v-on="on" v-else-if="shipment.taskStatus == 'Complete'"
                    color="green">task_alt</v-icon>
                </template>
                <span style="font-size: 12px">{{
                  shipment.taskStatus
                }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Task Creation Status
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item style="height: 30px" v-if="shipment.documentationStatus == 'OPEN'">
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green">task_alt</v-icon>
                </template>
                <span style="font-size: 12px">Complete</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Reset all Tasks
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top v-if="shipment.shipmentFile.synced && !shipment.fileLocked">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" :disabled="shipment.taskStatus != 'Complete'" @click="resetAllTasks"
                    :loading="resettingTasks" icon><v-icon>refresh</v-icon></v-btn>
                </template>
                <span style="font-size: 12px">Resets all shipment tasks.</span>
              </v-tooltip>
              <v-tooltip top v-else-if="shipment.fileLocked">
                <template v-slot:activator="{ on }"><v-icon v-on="on" color="amber">lock</v-icon>
                </template>
                <span style="font-size: 12px">File sync is locked, changes must be made via the container.</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
         
          <v-list-item style="height: 30px" v-for="relationship in relationships" :key="relationship.id"
            :loading="savingInvoiceStatus">
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"
                    :color="getInvoiceColor(relationship.invoiceStatus)">{{getInvoiceIcon(relationship.invoiceStatus)}}</v-icon>
                </template>
                <span style="font-size: 12px">{{
                  getInvoiceName(relationship.invoiceStatus)
                }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Invoice Status
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn icon small style="text-transform: none" v-bind="attrs" v-on="{ ...on, ...tooltipOn }"
                        :loading="savingInvoiceStatus">
                        <v-icon>expand_more</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Update Invoice Status</span>
                  </v-tooltip>
                </template>
                <v-list dense>
                  <v-list-item v-for="(invoice, index ) in invoiceStatusOptions" :key="index"
                    :style="{ 'border-left': `5px solid ${invoice.color}` }"
                    @click="relationship.invoiceStatus = invoice.value, updateInvoiceStatus(relationship)"
                    style="height: 30px">
                    <v-list-item-content class="text-left ml-0 pl-0">
                      <v-list-item-title>{{ invoice.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-subheader style="font-size: 12px; color: grey; height: 36px;">Shipment Actions</v-subheader>
        <v-divider></v-divider>
        <v-list dense class="my-0 py-0" style="background: transparent !important;">
          <v-list-item @click="processChange('CHANGE_DESTINATION')" style="height: 40px">
            <v-list-item-action>
              <v-icon color="blue">
                mode_of_travel
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Change Destination
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="processChange('CHANGE_PARTY')" style="height: 40px">
            <v-list-item-action>
              <v-icon color="teal">
                groups
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Change Party
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="processChange('CHANGE_PROFILE')" style="height: 40px">
            <v-list-item-action>
              <v-icon color="orange">
                handshake
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Change Profile
            </v-list-item-title>
          </v-list-item>
      
          <v-list-item @click="processChange('CHANGE_VESSEL')" style="height: 40px">
            <v-list-item-action>
              <v-icon color="blue-grey lighten-1">
                directions_boat
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Change Vessel
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="processChange('MOVE_CONTAINER')" style="height: 40px">
            <v-list-item-action>
              <v-icon color="amber darken-1">
                swap_horiz
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Move Container(s)
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="processChange('SHORT_SHIPMENT')" style="height: 40px">
            <v-list-item-action>
              <v-icon color="red darken-1">
                warning
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              Short Shipment
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-dialog v-model="eventModal" width="1000px" :fullscreen="$vuetify.breakpoint.mobile">
          <EventModal :event="event" :documents="uploads" :shipment="shipment" @close="eventModal = false"
            @update="updateEvent" @refreshEvents="refreshEvents" :allowDelete="true" />
        </v-dialog>

        <v-dialog v-model="customEventModal" persistent width="800px" :fullscreen="$vuetify.breakpoint.mobile">
          <v-card>
            <v-toolbar flat color="transparent"><v-toolbar-title>Create Custom Event</v-toolbar-title> <v-spacer></v-spacer>
              <v-btn id="event-save" color="primary" icon :disabled="validateCustomEvent" :loading="savingCustomEvent"
                @click="saveCustomEvent"><v-icon>save</v-icon></v-btn>
              <v-btn text @click="customEventModal = false, customEventItem = {}">X</v-btn></v-toolbar>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-autocomplete id="event-type" label="Event Type*" :items="customEventTypes"
                    v-model="customEventItem.shipmentCustomEventTypeId" outlined dense clearable item-text="description"
                    item-value="id" prepend-icon="format_list_bulleted"></v-autocomplete>
                  <v-autocomplete id="event-status" :items="availableParties" v-model="customEventItem.courier"
                    label="Courier To" outlined dense v-if="customEventItem.shipmentCustomEventTypeId == 7" clearable
                    item-text="name" item-value="value" prepend-icon="local_shipping"></v-autocomplete>
                  <v-autocomplete id="event-adress" :items="orgAdresses" v-model="customEventItem.destinationAddressId"
                    label="Courier Address" outlined dense v-if="customEventItem.courier && orgAdresses.length >= 1" clearable
                    item-text="fullAdress" item-value="id" prepend-icon="location_on"></v-autocomplete>
                  <v-autocomplete id="event-status"
                    v-if="customEventItem.shipmentCustomEventTypeId && customEventItem.shipmentCustomEventTypeId != 7"
                    :items="customEventStatuses" v-model="customEventItem.status" label="Event Status" outlined dense
                    clearable item-text="name" item-value="value" prepend-icon="star_outline"></v-autocomplete>
                  <v-text-field id="event-description"
                    v-if="customEventItem.shipmentCustomEventTypeId && customEventItem.shipmentCustomEventTypeId != 7"
                    outlined dense v-model="customEventItem.reference" placeholder="Reference"
                    prepend-icon="description"></v-text-field>
                  <v-textarea id="event-comment"
                    v-if="customEventItem.shipmentCustomEventTypeId && !customEventItem.shipmentCustomEventType.eventCode"
                    outlined rows="4" v-model="customEventItem.comment" placeholder="Comment" prepend-icon="comment"
                    dense></v-textarea>
                  <div v-if="customEventItem.status == 'Rejected' || customEventItem.status == 'REJECTED'">
                    <v-text-field id="event-rejected-reason"
                      v-if="customEventItem.shipmentCustomEventTypeId && !customEventItem.shipmentCustomEventType.eventCode"
                      outlined dense v-model="customEventItem.rejectReason" placeholder="Reason Rejected"
                      prepend-icon="comments_disabled"></v-text-field>
                    <v-text-field id="event-fault-party"
                      v-if="customEventItem.shipmentCustomEventTypeId && !customEventItem.shipmentCustomEventType.eventCode"
                      outlined dense v-model="customEventItem.faultParty" placeholder="Fault Party"
                      prepend-icon="dangerous"></v-text-field>
                  </div>
                </v-col>
                <v-col v-if="customEventItem.shipmentCustomEventTypeId && !customEventItem.shipmentCustomEventType.eventCode">
                  <div v-if="customEventItem.shipmentCustomEventTypeId === 1">
                    <v-autocomplete id="event-pickup" label="Pickup Location*" :items="organisationAddresses"
                      v-model="customEventItem.pickupAddressId" outlined dense clearable item-text="alias" item-value="id"
                      prepend-icon="home_filled"></v-autocomplete>
                    <v-autocomplete id="event-delivery" label="Delivery Location*" :items="organisationAddresses"
                      v-model="customEventItem.deliveryAddressId" outlined dense clearable item-text="alias" item-value="id"
                      prepend-icon="home"></v-autocomplete>
                  </div>
                  <div id="event-sub-date">
                    <v-menu v-model="sumbittedMenu" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="customEventItem.submittedAt" label="Submitted Date" prepend-icon="event"
                          readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                      </template>
                      <v-date-picker v-model="customEventItem.submittedAt" @input="sumbittedMenu = false"></v-date-picker>
                    </v-menu>
                  </div>
                  <div id="event-resub-date">
                    <v-menu v-model="resumbittedMenu" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="customEventItem.resubmittedAt" label="Resubmitted Date" prepend-icon="event"
                          readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                      </template>
                      <v-date-picker v-model="customEventItem.resubmittedAt" @input="resumbittedMenu = false"></v-date-picker>
                    </v-menu>
                  </div>
                  <div id="event-rejected-date">
                    <v-menu v-model="rejectedMenu" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="customEventItem.rejectedAt" label="Rejected Date" prepend-icon="event" readonly
                          v-bind="attrs" v-on="on" outlined dense></v-text-field>
                      </template>
                      <v-date-picker v-model="customEventItem.rejectedAt" @input="rejectedMenu = false"></v-date-picker>
                    </v-menu>
                  </div>
                  <div id="event-completed-date">
                    <v-menu v-model="completedMenu" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="customEventItem.completedAt" label="Completed Date" prepend-icon="event"
                          readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                      </template>
                      <v-date-picker v-model="customEventItem.completedAt" @input="completedMenu = false"></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-2" justify="center">
                <v-btn @click="deleteCustomEvent()" :loading="deletingCustomEvent" v-if="customEventItem.id" color="red"
                  style="text-transform: none"><v-icon>delete</v-icon> Delete</v-btn>

              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="shipmentChangeModal" :max-width="shipmentChangeType == 'CHANGE_PROFILE' ? '1400px' : '750px'"
      :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <ShipmentChange :shipment="shipment" :containers="bookings" :type="shipmentChangeType"
        @close="shipmentChangeModal = false" :documents="uploads" :customerId="customerId" :relationships="relationships"
        @refresh="$emit('refresh'), shipmentChangeModal = false" />
    </v-dialog>
    </div>
</template>

<script>
import ShipmentChange from "../../../ShipmentSummary/ShipmentChange.vue";
import EventModal from "../../../SystemComponents/EventModal.vue";
export default {
  components: {
    ShipmentChange,
    EventModal
  },
    props: [
        'shipment',
        'relationships',
        'updateKey',
        'uploads',
        'customerId',
        'bookings'
    ],
    data: () => ({
        customEventModal: false,
        event: {},
        events: [],
        customEvents: [],
        eventModal: false,
        hookingShipment: false,
        shipmentChangeType: null,
        shipmentChangeModal: false,
        savingInvoiceStatus: false,
        savingCustomEvent: false,
        sumbittedMenu: false,
        resumbittedMenu: false,
        rejectedMenu: false,
        completedMenu: false,
        deletingCustomEvent: false,
        orgAdresses: [],
        customEventTypes: [],
        customEventItem: {},
        organisationAddresses: [],
        invoiceStatusOptions: [
          { name: 'Not Ready', value: 'NOTREADY', color: 'grey', icon: 'schedule' },
          { name: 'Ready to Invoice', value: 'READY', color: '#DC7633', icon: 'pending' },
          { name: 'Invoiced', value: 'INVOICED', color: '#5DADE2 ', icon: 'paid' },
          { name: 'Paid', value: 'PAID', color: '#229954', icon: 'task_alt' },
        ],
    }),
    async created() {},
    watch: {
        'updateKey': {
            immediate: true,
            handler() {
                this.loadEvents()
            }
        },
        'customEventItem.courier': {
          handler: function (newCourier, oldCourier) {
            if (newCourier !== oldCourier) {
              this.fetchOrgAddresses(newCourier);
            }
          },
          immediate: true, // to run the handler immediately after the watcher is created
        },
        'customEventItem.shipmentCustomEventTypeId': {
          immediate: true,
          handler(val) {
            if (val) {
              let find = this.customEventTypes.find(x => x.id == val)
              if (find) {
                this.customEventItem.shipmentCustomEventType = find
              }
            } else {
              this.customEventItem.shipmentCustomEventType = {}
            }
          }
        }
    },
    computed: {
        validateCustomEvent() {
          let res = true
          if (this.customEventItem.shipmentCustomEventTypeId) {
            if (this.customEventItem.shipmentCustomEventTypeId === 1) {
              if (this.customEventItem.pickupAddressId && this.customEventItem.deliveryAddressId) {
                res = false
              } else {
                res = true
              }
            } else {
              res = false
            }
          }
          return res
        },
        availableParties() {
          let result = []
          let parties = ['shipper', 'onBehalfShipper', 'forwarder', 'onBehalfForwarder', 'consignee', 'onBehalfConsignee', 'buyer', 'onBehalfBuyer', 'firstNotify', 'secondNotify', 'courierParty']
          for (let i = 0; i < parties.length; i++) {
            if (this.shipment[parties[i]]) {
              let findExisting = result.find(x => x.value == this.shipment[parties[i] + 'Id'])

              if (!findExisting) {
                result.push({
                  name: this.shipment[parties[i]].name,
                  value: this.shipment[parties[i] + 'Id']
                })
              }
            }
          }
          if (this.relationships && this.relationships.length > 0) {
            for (let i = 0; i < this.relationships.length; i++) {
              let findExisting = result.find(x => x.value == this.relationships[i].customerId)
              if (!findExisting) {
                result.push({
                  name: this.relationships[i].customer.name,
                  value: this.relationships[i].customerId
                })
              }
            }
          }
          result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          // result.push({ name: 'Other', value: null })
          return result

        },
    },
    methods: {
        async fetchOrgAddresses() {
          console.log('this.customEventItem.courier', this.customEventItem.courier)
          let res = await this.$API.getAllOrgAdresses(this.customEventItem.courier)
          this.orgAdresses = res
        },
        async loadEvents() {
            this.getCustomEvents()
            this.getShipmentEvents(this.shipment.id)
            this.getShipmentContainerEvents(this.shipment.id)
        },
        async getCustomEvents() {
          this.loadingCustomEvents = true
          this.customEvents = await this.$API.getShipmentCustomEvents(this.shipment.id)
          this.customEventTypes = await this.$API.getCustomEventTypes()
          this.organisationAddresses = await this.$API.getOrganisationAddress()
          this.loadingCustomEvents = false
        },
        processChange(type) {
          this.shipmentChangeType = type;
          this.shipmentChangeModal = true;
        },
        async getShipmentEvents(id) {
            this.events = await this.$API.getShipmentEvents(id);
        },
        async getShipmentContainerEvents(id) {
            this.shipmentTimeline = await this.$API.getShipmentContainerEvents(id);
        },
        viewEvent(event) {
          this.event = event;
          this.eventModal = true;
        },
        editCustomEvent(event) {
          this.customEventItem = event
          this.customEventModal = true
        },
        async resetAllTasks() {
          this.resettingTasks = true;
          await this.$API.resetAllShipmentTasks(this.shipment.id);
          this.$emit('refreshTasks')
          this.$message.success("Successfully reset all tasks");
          this.resettingTasks = false;
        },
       
        async updateInvoiceStatus(relationship) {
          this.savingInvoiceStatus = true;
          await this.$API.updateShipmentRelationship(relationship)
          this.$message.success("Successfully updated invoice status");
          this.savingInvoiceStatus = false;
        },
        async saveCustomEvent() {
          this.savingCustomEvent = true
          if (this.customEventItem && this.customEventItem.courier) {
            console.log('this.customEventItem.courier', this.customEventItem.destinationAddressId)
            if (!this.customEventItem.destinationAddressId) {
              let destinationId = await this.$API.getSingleOrganisionAdress(this.customEventItem.courier)
              this.customEventItem.destinationAddressId = destinationId.id
            }
            console.log('this.customEventItem.destinationAddressId', this.customEventItem.destinationAddressId);
            let originId = await this.$API.getSingleUserOrgAdress()
            console.log('originId', originId)
            this.customEventItem.originAddressId = originId.organisationAddressId
          }
          if (this.customEventItem.shipmentCustomEventType && this.customEventItem.shipmentCustomEventType.eventCode) {
            this.customEventItem.eventCode = this.customEventItem.shipmentCustomEventType.eventCode
            this.customEventItem.shipmentId = this.shipment.id
            if (!this.customEventItem.status || this.customEventItem.status == '') { this.customEventItem.status = 'Not Started' }
            if (this.customEventItem.shipmentCustomEventType.eventCode == 'ZA_DHL_Waybill') {
              let res = await this.$API.createManualShipmentDocument({
                shipmentId: this.shipment.id,
                eventCode: this.customEventItem.shipmentCustomEventType.eventCode,
                quantityType: 'Shipment',
                submitted: this.customEventItem.submitted,
                resubmitted: this.customEventItem.resubmitted,
                completed: this.customEventItem.completed,
                eventStatus: this.customEventItem.status,
              })
              // console.log('res.data',res.data[0].id)
              this.customEventItem.shipmentDocumentTypeId = res.data[0].id
              this.customEventItem.manuallyCreated = true
              this.custom
            }
            await this.$API.createShipmentEvent(this.customEventItem)
            this.$emit('refreshEvents')
          } else {
            this.customEventItem.shipmentId = this.shipment.id
            this.customEventItem.createdById = this.$store.state.user.id
            this.customEventItem.status = this.setCustomEventStatus()
            // if(!this.customEventItem.status || this.customEventItem.status == ''){this.customEventItem.status = 'Pending'}
            if (this.customEventItem && this.customEventItem.id) {
              this.customEventItem = await this.$API.updateShipmentCustomEvent(this.customEventItem, this.customEventItem.id)
            } else {
              this.customEventItem = await this.$API.createShipmentCustomEvent(this.customEventItem)
            }
            this.getCustomEvents()
          }
          this.savingCustomEvent = false
          this.customEventItem = {}
          this.customEventModal = false
        },
        async deleteCustomEvent() {
          this.deletingCustomEvent = true
          this.$confirm(
            "Are you sure you want to delete this event?",
            "Confirm",
            {
              center: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              type: "plain",
            }
          )
            .then(async () => {
              this.customEventItem.isDeleted = true;
              this.customEventItem.isActive = false;
              await this.$API.updateShipmentCustomEvent(this.customEventItem, this.customEventItem.id);
              this.$message({
                type: "success",
                message: "Successfully deleted!",
              });
              this.getCustomEvents()
              this.customEventModal = false
              this.customEventItem = {}
              this.deletingCustomEvent = false
            })
            .catch(() => {
              this.deletingCustomEvent = false
              this.$message({
                type: "info",
                message: "Delete canceled",
              });
            });
        },
        updateEvent(event) {
          let find = this.events.findIndex(x => x.id == event.id)
          this.events[find] = event
        },
        async refreshEvents() {
          this.eventsLoading = true
          this.events = await this.$API.getShipmentEvents(this.shipment.id);
          this.eventsLoading = false
        },
        setCustomEventStatus() {
          let status = this.customEventItem.status
          if (this.customEventItem.submittedAt) {
            status = 'Submitted'
            if (this.customEventItem.shipmentCustomEventTypeId == 3) {
              this.customEventItem.completedAt = this.customEventItem.submittedAt
              status = 'Completed'
            }
          }
          if (this.customEventItem.resubmittedAt) {
            status = 'Resubmitted'
          }
          if (this.customEventItem.completedAt) {
            status = 'Completed'
          }
          return status;
        },

        getInvoiceIcon(status) {
          let find = this.invoiceStatusOptions.find(x => x.value == status);
          return find ? find.icon : 'schedule'
        },
        getInvoiceColor(status) {
          let find = this.invoiceStatusOptions.find(x => x.value == status);
          return find ? find.color : 'grey'
        },
        getInvoiceName(status) {
          let find = this.invoiceStatusOptions.find(x => x.value == status);
          return find ? find.name : null
        },
        getStatus(status) {
          switch (status) {
            case "Not started":
              return { name: status, icon: "schedule", color: "grey" };
            case "Pending":
              return { name: status, icon: "pending", color: "orange" };
            case "In Progress":
              return { name: status, icon: "sync", color: "blue" };
            case "Delivered":
              return { name: status, icon: "task_alt", color: "green" };
            case "In Transit":
              return { name: status, icon: "local_shipping", color: "blue" };
            case "Complete":
              return { name: status, icon: "task_alt", color: "green" };
            case "Completed":
              return { name: status, icon: "task_alt", color: "green" };
            case "File Generated":
              return { name: status, icon: "task", color: "orange" };
            case "Success":
              return { name: status, icon: "task_alt", color: "green" };
            case "Cancelled":
              return { name: status, icon: "cancel", color: "grey" };
            case "Draft":
              return { name: status, icon: "edit_note", color: "orange" };
            case "Error":
              return { name: status, icon: "error", color: "red" };
            case "Failed":
              return { name: status, icon: "cancel", color: "red" };
            case "Rejected":
              return { name: status, icon: "cancel", color: "red" };
            case "Submitted":
              return { name: status, icon: "mark_email_read", color: "blue" };
            case "Created":
              return { name: status, icon: "mark_email_read", color: "blue" };
            case "Resubmitted":
              return { name: status, icon: "mark_email_read", color: "orange" };
            case "Not Required":
              return { name: status, icon: "close", color: "grey" };
            case "Approved":
              return { name: status, icon: "fact_check", color: "green" };
            default:
              return {
                icon: "info",
                color: "grey",
                name: status,
              };
          }
        },
    },
}
</script>

<style scoped>
v-list-item {
    height: 40px;
}
</style>