<template>
  <div>
    <v-card :loading="settingShipmentProfile" flat>
      <!-- <v-card-title>
        Search Shipment Profiles
        <v-spacer></v-spacer>
        <v-btn text @click="close()">X</v-btn>
      </v-card-title> -->
      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" sm="4">
            <v-autocomplete label="Customer" :loading="loadingOrganisations" outlined dense v-model="selectedCustomerId" :items="organisations" :filter="filterObject" item-text="name" item-value="id">
                <template v-slot:item="data">
                    <v-list-item-action>
                        <v-avatar size="32" :color="data.item.logo?'white': 'secondary'">
                        <v-img v-if="data.item.logo" :src="data.item.logo" contain></v-img>
                            <h3 v-else>{{ data.item.name.charAt(0) }}</h3>
                        </v-avatar>
                    </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="data.item.alias">
                  {{ data.item.alias }}</v-list-item-subtitle>
              </v-list-item-content>
            </template></v-autocomplete>
</v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              placeholder="Search"
              autofocus
              prepend-inner-icon="search"
              v-model="searchProfiles"
              outlined
              dense
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          id="profile-info"
          style="cursor: pointer; font-size: 12px"
          :items="shipmentProfiles.data"
          dense
          :search="searchProfiles"
          :loading="loadingShipmentProfiles"
          :headers="headers"
          fixed-header
          height="55vh"
          @click:row="setShipmentProfile"
        >
          <template v-slot:[`item.systemReference`]="{ item }">
            <b>{{ item.systemReference }}</b>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.status == 'Draft'"
              color="blue"
              class="white--text"
              small
              >Draft</v-chip
            >
            <v-chip
              v-else-if="item.status == 'Approved'"
              color="success"
              class="white--text"
              small
              >Approved</v-chip
            >
          </template>
          <template v-slot:[`item.shipper.name`]="{ item }">
            <span v-if="item.shipper">{{ item.shipper.name }}</span>
            <span>
              <span v-if="item.onBehalfShipper">
                <br />
                On behalf of {{ item.onBehalfShipper.name }}</span
              >
            </span>
          </template>
          <template v-slot:[`item.forwarder.name`]="{ item }">
            <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
            <span>
              <span v-if="item.onBehalfForwarder">
                <br />
                On behalf of {{ item.onBehalfForwarder.name }}</span
              >
            </span>
          </template>
          <template v-slot:[`item.consignee.name`]="{ item }">
            <span v-if="item.consignee">{{ item.consignee.name }}</span>
            <span>
              <span v-if="item.onBehalfConsignee">
                <br />
                On behalf of {{ item.onBehalfConsignee.name }}</span
              >
            </span>
          </template>
          <template v-slot:[`item.buyer.name`]="{ item }">
            <span v-if="item.buyer">{{ item.buyer.name }}</span>
            <span>
              <span v-if="item.onBehalfBuyer">
                <br />
                On behalf of {{ item.onBehalfBuyer.name }}</span
              >
            </span>
          </template>
          <template v-slot:[`item.loadingPorts`]="{ item }">
            <v-chip
              color="success"
              pill
              outlined
              small
              v-if="item.allOriginPorts"
            >
              <v-icon small left>check</v-icon> All Ports</v-chip
            >
            <div v-else>
              <span
                v-for="(port, i) in item.consigneeProfilePorts.filter(
                  (x) => x.type == 'loading'
                )"
                :key="port.id"
              >
                <span v-if="i > 0">, </span>{{ port.name }}</span
              >
            </div>
          </template>
          <template v-slot:[`item.dischargePorts`]="{ item }">
            <v-chip
              color="success"
              pill
              outlined
              small
              v-if="item.allDestinationPorts"
            >
              <v-icon small left>check</v-icon> All Ports</v-chip
            >
            <div v-else>
              <span
                v-for="(port, i) in item.consigneeProfilePorts.filter(
                  (x) => x.type == 'discharge'
                )"
                :key="port.id"
              >
                <span v-if="i > 0">, </span>{{ port.name }}</span
              >
            </div>
          </template>
          <template v-slot:[`item.originCountry.name`]="{ item }">
            <v-tooltip top v-if="item.originCountry">
              <template v-slot:activator="{ on }">
                <v-avatar size="32" v-on="on">
                  <v-img
                    contain
                    v-if="item.originCountry && item.originCountry.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </template>
              <span style="font-size: 12px">{{ item.originCountry.name }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.documentationPOD`]="{ item }">
            <template v-if="item.consigneeProfilePorts.length > 0">
              {{ item.consigneeProfilePorts[0].name }}
            </template>
            <template v-else>
              {{ "-" }}
            </template>
          </template>

          <template v-slot:[`item.destinationCountry.name`]="{ item }">
            <v-tooltip top v-if="item.destinationCountry">
              <template v-slot:activator="{ on }">
                <v-avatar size="32" v-on="on">
                  <v-img
                    contain
                    v-if="
                      item.destinationCountry && item.destinationCountry.iso2
                    "
                    :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </template>
              <span style="font-size: 12px">{{
                item.destinationCountry.name
              }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.incoTerm`]="{ item }">
            <v-chip
              small
              outlined
              class="mt-1"
              v-for="term in item.consigneeProfileIncoTerms"
              :key="term.id"
              >{{ term.incoTerm }}</v-chip
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["customerId", "refreshKey"],
  data: () => ({
    loadingOrganisations: false,
    loadingShipmentProfiles: false,
    headers: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    organisations: [],
    searchProfiles: null,
    selectedCustomerId: null,
    settingShipmentProfile: false,
    shipmentProfiles: {
        data: [],
        total: 0
    }
  }),
  watch: {
    customerId(val) {
      this.selectedCustomerId = val;
      console.log("Setting customerId", val)
    },
    refreshKey(val){
        console.log("Profile refreshKey", val)
        if(!this.loadingShipmentProfiles){
            this.getShipmentProfiles();
        }
    },
    selectedCustomerId(val) {
        if(val){
            this.getShipmentProfiles();
        }
    },
  },
  created(){
    this.getRelations();
  },
  mounted(){
    console.log("Customer ID",this.customerId)
    if(!this.selectedCustomerId){
        this.selectedCustomerId = this.customerId;
    }
  },
  methods: {
    filterObject(item, queryText, itemText) {
      return (
        (item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1) ||
          (item.alias && (item.alias.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1))
      );
    },
    async getRelations() {
      this.loadingOrganisations = true;
      let result = await this.$API.getRelation({
        // params: this.params,
      });
      this.organisations = result.data.filter(x=>x.isCustomer && x.isActive).map(x=>x.relatedOrganisation);
      this.loadingOrganisations = false;
    },
    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.selectedCustomerId,
        {}
      );
      this.loadingShipmentProfiles = false;
    },
    async setShipmentProfile(item) {
      this.settingShipmentProfile = true;
      this.$emit("processShipmentProfile", {
        profile: item,
        customerId: this.selectedCustomerId,
        consigneeProfileId: item.id,
        shipperId: item.shipperId  ?? null,
        forwarderId: item.forwarderId  ?? null,
        consigneeId: item.consigneeId  ?? null,
        buyerId: item.buyerId  ?? null,
        onBehalfShipperId: item.onBehalfShipperId  ?? null,
        onBehalfForwarderId: item.onBehalfForwarderId  ?? null,
        onBehalfConsigneeId: item.onBehalfConsigneeId ?? null,
        onBehalfBuyerId: item.onBehalfBuyerId ?? null,
        firstNotifyId: item.firstNotifyId ?? null,
        secondNotifyId: item.secondNotifyId ?? null,
        courierPartyId: item.courierPartyId ?? null,
        ucrNo: item.ucrNo ?? null
      });
    //   await this.$API.setShipmentProfile(item.id);
    //   this.settingShipmentProfile = false;
    //   this.$emit("close");
    },
  },
};
</script>